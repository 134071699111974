import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ReactInputMask from "react-input-mask";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "../../../../hooks/useAuth";
import { getBeneficios } from "../../../../services/beneficiarios";
import { getCep } from "../../../../services/services";

export function NovoCadastroContent({
  tipo, //beneficiario, cartao, voucher, beneficio
  errors,
  setErrors,
  conta,
  setConta,
}) {
  const token = useAuth();
  const { id } = useParams();
  const [tiposBeneficio, setTiposBeneficio] = useState([]);
  const handleCep = async (e) => {
    e.preventDefault();
    setErrors((prev) => ({
      ...prev,
      "endereco.cep": null,
    }));

    try {
      const response = await getCep(e.target.value);
      setConta((prev) => ({
        ...prev,
        endereco: {
          ...prev.endereco,
          rua: response.data.logradouro,
          complemento: response.data.complemento,
          bairro: response.data.bairro,
          cidade: response.data.localidade,
          estado: response.data.uf,
        },
      }));
    } catch (err) {
      console.log(err);
      setErrors((prev) => ({
        ...prev,
        "endereco.cep": ["Erro ao buscar dados do CEP"],
      }));
    }
  };

  const getTiposBeneficio = async () => {
    try {
      const { data } = await getBeneficios(token, id);
      setTiposBeneficio(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (tipo === "cartao" || tipo === "voucher") {
      getTiposBeneficio();
    }
  }, [tipo]);

  return (
    <Grid container spacing={4}>
      {tipo === "beneficiario" ? (
        <>
          <Grid item xs={12}>
            <TextField
              label={"Nome completo"}
              value={conta?.beneficiario?.nome}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficiario: {
                    ...prev.beneficiario,
                    nome: e.target.value,
                  },
                }))
              }
              error={errors["beneficiario.nome"]}
              helperText={
                errors["beneficiario.nome"]
                  ? errors["beneficiario.nome"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"Email"}
              value={conta?.beneficiario?.email}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficiario: {
                    ...prev.beneficiario,
                    email: e.target.value,
                  },
                }))
              }
              error={errors["beneficiario.email"]}
              helperText={
                errors["beneficiario.email"]
                  ? errors["beneficiario.email"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <ReactInputMask
              mask={"99/99/9999"}
              value={conta?.beneficiario?.data_nascimento}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficiario: {
                    ...prev.beneficiario,
                    data_nascimento: e.target.value,
                  },
                }))
              }
            >
              {() => (
                <TextField
                  label={"Data de nascimento"}
                  error={errors["beneficiario.data_nascimento"]}
                  helperText={
                    errors["beneficiario.data_nascimento"]
                      ? errors["beneficiario.data_nascimento"]?.join(" ")
                      : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item xs={12}>
            <ReactInputMask
              mask={"999.999.999-99"}
              value={conta?.beneficiario?.documento}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficiario: {
                    ...prev.beneficiario,
                    documento: e.target.value,
                  },
                }))
              }
              disabled={true}
            >
              {() => (
                <TextField
                  label={"Documento"}
                  error={errors["beneficiario.documento"]}
                  helperText={
                    errors["beneficiario.documento"]
                      ? errors["beneficiario.documento"]?.join(" ")
                      : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"Celular"}
              value={conta?.beneficiario?.celular}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficiario: {
                    ...prev.beneficiario,
                    celular: e.target.value,
                  },
                }))
              }
              error={errors["beneficiario.celular"]}
              helperText={
                errors["beneficiario.celular"]
                  ? errors["beneficiario.celular"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs={4}>
            <ReactInputMask
              mask={"99999-999"}
              value={conta?.endereco?.cep}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    cep: e.target.value,
                  },
                }))
              }
              onBlur={(e) => handleCep(e)}
            >
              {() => (
                <TextField
                  label={"CEP"}
                  error={errors["endereco.cep"]}
                  helperText={
                    errors["endereco.cep"]
                      ? errors["endereco.cep"]?.join(" ")
                      : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"Rua"}
              value={conta?.endereco?.rua}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    rua: e.target.value,
                  },
                }))
              }
              error={errors["endereco.rua"]}
              helperText={
                errors["endereco.rua"]
                  ? errors["endereco.rua"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={"Número"}
              value={conta?.endereco?.numero}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    numero: e.target.value,
                  },
                }))
              }
              error={errors["endereco.numero"]}
              helperText={
                errors["endereco.numero"]
                  ? errors["endereco.numero"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Complemento"}
              value={conta?.endereco?.complemento}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    complemento: e.target.value,
                  },
                }))
              }
              error={errors["endereco.complemento"]}
              helperText={
                errors["endereco.complemento"]
                  ? errors["endereco.complemento"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Bairro"}
              value={conta?.endereco?.bairro}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    bairro: e.target.value,
                  },
                }))
              }
              error={errors["endereco.bairro"]}
              helperText={
                errors["endereco.bairro"]
                  ? errors["endereco.bairro"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Cidade"}
              value={conta?.endereco?.cidade}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    cidade: e.target.value,
                  },
                }))
              }
              error={errors["endereco.cidade"]}
              helperText={
                errors["endereco.cidade"]
                  ? errors["endereco.cidade"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Estado"}
              value={conta?.endereco?.estado}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  endereco: {
                    ...prev.endereco,
                    estado: e.target.value,
                  },
                }))
              }
              error={errors["endereco.estado"]}
              helperText={
                errors["endereco.estado"]
                  ? errors["endereco.estado"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={true}
            />
          </Grid>
        </>
      ) : null}

      {tipo === "cartao" ? (
        <>
          <Grid item xs={12}>
            <ReactInputMask
              mask={"999.999.999-99"}
              value={conta?.cartao?.documento}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  cartao: {
                    ...prev.cartao,
                    documento: e.target.value,
                  },
                }))
              }
            >
              {() => (
                <TextField
                  label={"Documento"}
                  error={errors["documento"]}
                  helperText={
                    errors["documento"] ? errors["documento"]?.join(" ") : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item xs={12}>
            <ReactInputMask
              mask={"99/99/9999"}
              value={conta?.cartao?.data_solicitacao}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  cartao: {
                    ...prev.cartao,
                    data_solicitacao: e.target.value,
                  },
                }))
              }
            >
              {() => (
                <TextField
                  label={"Data da solicitação"}
                  error={errors["data_solicitacao"]}
                  helperText={
                    errors["data_solicitacao"]
                      ? errors["data_solicitacao"]?.join(" ")
                      : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            </ReactInputMask>
            <Typography style={{ fontSize: 14 }}>
              A data de solicitação deve ser em no mínimo 10 dias e deve ser um
              dia útil
            </Typography>
          </Grid>
        </>
      ) : null}

      {tipo === "voucher" ? (
        <>
          <Grid item xs={12}>
            <ReactInputMask
              mask={"999.999.999-99"}
              value={conta?.voucher?.documento}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  voucher: {
                    ...prev.voucher,
                    documento: e.target.value,
                  },
                }))
              }
            >
              {() => (
                <TextField
                  label={"Documento"}
                  error={errors["documento"]}
                  helperText={
                    errors["documento"] ? errors["documento"]?.join(" ") : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"Chave Pix"}
              value={conta?.voucher?.chave_pix}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  voucher: {
                    ...prev.voucher,
                    chave_pix: e.target.value,
                  },
                }))
              }
              error={errors["chave_pix"]}
              helperText={
                errors["chave_pix"] ? errors["chave_pix"]?.join(" ") : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>
        </>
      ) : null}

      {tipo === "cartao" || tipo === "voucher" ? (
        <Grid item xs={12}>
          <Select
            variant="outlined"
            fullWidth
            required
            value={conta?.tipo_beneficio_id}
            onChange={(e) =>
              setConta((prev) => ({
                ...prev,
                tipo_beneficio_id: e.target.value,
              }))
            }
          >
            {tiposBeneficio.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.nome_beneficio}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      ) : null}

      {tipo === "beneficio" ? (
        <>
          <Grid item xs={12}>
            <TextField
              label={"Nome do benefício"}
              value={conta?.beneficio?.nome_beneficio}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficio: {
                    ...prev.beneficio,
                    nome_beneficio: e.target.value,
                  },
                }))
              }
              error={errors["nome_beneficio"]}
              helperText={
                errors["nome_beneficio"]
                  ? errors["nome_beneficio"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"ID do benefício"}
              type="number"
              value={conta?.beneficio?.cdProduto}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficio: {
                    ...prev.beneficio,
                    cdProduto: e.target.value,
                  },
                }))
              }
              error={errors["cdProduto"]}
              helperText={
                errors["cdProduto"] ? errors["cdProduto"]?.join(" ") : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Select
              variant="outlined"
              fullWidth
              required
              value={conta?.beneficio?.tipo}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficio: {
                    ...prev.beneficio,
                    tipo: e.target.value,
                  },
                }))
              }
            >
              <MenuItem key={0} value={"beneficiario"}>
                Voucher
              </MenuItem>
              <MenuItem key={1} value={"cartao"}>
                Cartão
              </MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"Nome da prefeitura"}
              value={conta?.beneficio?.nome_prefeitura}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficio: {
                    ...prev.beneficio,
                    nome_prefeitura: e.target.value,
                  },
                }))
              }
              error={errors["nome_prefeitura"]}
              helperText={
                errors["nome_prefeitura"]
                  ? errors["nome_prefeitura"]?.join(" ")
                  : null
              }
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={"Sigla da prefeitura"}
              value={conta?.beneficio?.sigla}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficio: {
                    ...prev.beneficio,
                    sigla: e.target.value,
                  },
                }))
              }
              error={errors["sigla"]}
              helperText={errors["sigla"] ? errors["sigla"]?.join(" ") : null}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12}>
            <ReactInputMask
              mask={"99.999.999/9999-99"}
              value={conta?.beneficio?.documento}
              onChange={(e) =>
                setConta((prev) => ({
                  ...prev,
                  beneficio: {
                    ...prev.beneficio,
                    documento: e.target.value,
                  },
                }))
              }
            >
              {() => (
                <TextField
                  label={"CNPJ"}
                  error={errors["documento"]}
                  helperText={
                    errors["documento"] ? errors["documento"]?.join(" ") : null
                  }
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  required
                />
              )}
            </ReactInputMask>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
