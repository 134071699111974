import { Box, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import ReactCodeInput from "react-code-input";

import CustomButton from "../../../../components/CustomButton/CustomButton";
import { APP_CONFIG } from "../../../../constants/config";

const useStyles = makeStyles((theme) => ({
  modal: {
    outline: " none",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    position: "absolute",
    top: "10%",
    left: "35%",
    width: "30%",
    height: "80%",
    backgroundColor: "white",
    border: "0px solid #000",
    boxShadow: 24,
  },
  closeModalButton: {
    alignSelf: "end",
    padding: "5px",
    "&:hover": {
      backgroundColor: APP_CONFIG.mainCollors.primaryVariant,
      cursor: "pointer",
    },
  },
}));

export function OTPAprovar({
  aprovarTodos = true,
  show = false,
  setShow = () => null,
  handleAprovarPagamento = () => null,
}) {
  const classes = useStyles();
  const [dataToken, setDataToken] = useState("");

  return (
    <Modal open={show} onBackdropClick={() => setShow(false)}>
      <Box className={classes.modal}>
        <Box
          className={classes.closeModalButton}
          onClick={() => setShow(false)}
        >
          <CloseIcon />
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "30px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Montserrat-ExtraBold",
              fontSize: "16px",
              color: APP_CONFIG.mainCollors.primary,
              fontWeight: "bold",
            }}
          >
            Preencha o campo com o token do seu aplicativo.
          </Typography>

          <Typography
            style={{
              fontFamily: "Montserrat-ExtraBold",
              fontSize: "16px",
              color: "#F1C40F",
              fontWeight: "bold",
            }}
          >
            {aprovarTodos
              ? "Você irá autorizar TODOS os pagamentos"
              : "Você irá autorizar apenas os pagamentos selecionados"}
          </Typography>

          <ReactCodeInput
            value={dataToken}
            onChange={(e) => setDataToken(e)}
            type="number"
            fields={6}
            inputStyle={{
              fontFamily: "monospace",
              margin: "4px",
              marginTop: "30px",
              MozAppearance: "textfield",
              width: "30px",
              borderRadius: "28px",
              fontSize: "20px",
              height: "50px",
              paddingLeft: "7px",

              color: APP_CONFIG.mainCollors.primary,
              border: `1px solid ${APP_CONFIG.mainCollors.primary}`,
            }}
          />
          {/* {errors.token ? (
            <FormHelperText
              style={{
                fontSize: 14,
                textAlign: "center",
                fontFamily: "Montserrat-ExtraBold",
                color: "red",
              }}
            >
              {errors.token.join(" ")}
            </FormHelperText>
          ) : null} */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px",
            }}
          >
            <Box style={{ marginTop: "10px" }}>
              <CustomButton
                variant="contained"
                color="purple"
                style={{ marginTop: "10px" }}
                onClick={() => handleAprovarPagamento(dataToken, aprovarTodos)}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat-Regular",
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Aprovar
                </Typography>
              </CustomButton>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "24px",
            }}
          >
            <img
              src={APP_CONFIG.assets.tokenImageSvg}
              style={{ width: "60%" }}
              alt={"Imagem do token"}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
